<template>
  <div class="col-12 mt-4 p-0 mb-3">
    <div class="row col-12">
      <div class="col-12 col-xl-4 mb-3">
        <b-form-file
          accept=".xlsx , .xls"
          v-model="file1"
          :state="Boolean(file1)"
          size="sm"
          placeholder="เลือกไฟล์ที่ต้องการอัพโหลด"
          drop-placeholder="Drop file here..."
          @change="onChange"
        ></b-form-file>
      </div>
      <div class="col-12 col-xl-4 mb-2">
        <button
          type="button"
          class="btn btn-sm bt-bnb"
          @click="UploadSPUData()"
        >
          อัพโหลดข้อมูล
        </button>
        <button
          type="button"
          class="ml-1 btn-sm btn bt"
          @click="ClearTableSPUData()"
        >
          ยกเลิก
        </button>

        <button type="button" class="ml-1 btn btn-sm bt-VFIX">
          <a :href="file_SPU" download class="text-white"> ไฟล์ตัวอย่าง</a>
        </button>
      </div>

      <div class="col-12 col-xl-3 mb-3"></div>
    </div>
    <div class="row mx-0 p-0">
      <div class="row col-12">
        <div class="col-12 col-xl-6">
          <p style="font-weight: bold">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
      </div>

      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";
export default {
  name: "UploadSPU",
  data() {
    return {
      file_SPU: require("@/files/SPU_Example.xlsx"),
      file1: null,
      items: null,
      itemlist: null,
      totalRows: 0,
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "class", label: "class", class: "text-left" },
        { key: "sku", label: "sku", class: "text-left" },
        { key: "spu", label: "spu", class: "text-left" },
        { key: "spuname", label: "spuname", class: "text-left" },
        { key: "spuename", label: "spuename", class: "text-left" },
        { key: "bycode", label: "bycode", class: "text-left" },
      ],
    };
  },
  methods: {
    async onChange(event) {
      try {
        this.file = event.target.files ? event.target.files[0] : null;
        let fname = this.file.name;
        fname = fname.toLowerCase();
        if (fname.indexOf(".xls") < 0)
          throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;

        if (this.file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let rows = XLSX.utils.sheet_to_row_object_array(ws);

            var jsnSPUData = rows.map((row) => ({
              class: String(row.class).trim(),
              sku: String(row.sku).trim(),
              spu: String(row.spu).trim(),
              spuname: String(row.spuname).trim(),
              spuename: String(row.spuename).trim(),
              bycode: String(row.bycode).trim(),
            }));

            let findBlanks = jsnSPUData.find(
              (itm) =>
                itm.class == "" ||
                itm.class == "undefined" ||
                itm.class == "#VALUE!" ||
                itm.sku == "" ||
                itm.sku == "undefined" ||
                itm.sku == "#VALUE!" ||
                itm.spu == "" ||
                itm.spu == "undefined" ||
                itm.spu == "#VALUE!" ||
                itm.spuname == "" ||
                itm.spuname == "undefined" ||
                itm.spuname == "#VALUE!" ||
                itm.spuename == "" ||
                itm.spuename == "undefined" ||
                itm.spuename == "#VALUE!" ||
                itm.bycode == "" ||
                itm.bycode == "undefined" ||
                itm.bycode == "#VALUE!"
            );

            if (findBlanks) {
              this.$serviceMain.showErrorAlert(
                this,
                `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน CLASS, SKU,SPU, SPUNAME, SPUENAME, BYCODE `
              );
            }
            this.items = jsnSPUData;
            this.itemlist = jsnSPUData;
            this.totalRows = jsnSPUData.length;
          };
          reader.readAsBinaryString(this.file);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async UploadSPUData() {
      try {
        if (this.items == null) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;
        const data = {
          jsnSPUData: this.items,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/upload_excel_spu`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(
          this,
          "อัพโหลดข้อมูลสำเร็จ / Complete"
        );

        await this.$store.dispatch("SPUProductActions/getDataSPU");

        await this.ClearTableSPUData();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async ClearTableSPUData() {
      this.file1 = null;
      this.items = null;
      this.itemlist = null;
      this.totalRows = 0;
    },
  },
};
</script>
