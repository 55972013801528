<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Mapping SPU</p>
          </div>
        </div>
        <div class="row m-0 p-0 w-100">
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="SPU List" :title-link-class="linkClass(0)">
                <TableMonitorSPU />
              </b-tab>
              <b-tab title="Upload" :title-link-class="linkClass(1)">
                <UploadSPU />
              </b-tab>
              <b-tab title="Error" :title-link-class="linkClass(2)">
                <template #title>
                  <span>
                    Error
                    <b-badge v-if="errorCount" pill variant="danger">{{
                      errorCount
                    }}</b-badge>
                  </span>
                </template>
                <UploadSPU_Error />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableMonitorSPU from "../../components/Manage/Attributes/TableMonitorSPU.vue";
import UploadSPU from "../../components/Manage/Attributes/UploadSPU.vue";
import UploadSPU_Error from "../../components/Manage/Attributes/TableMonitorSPU_Error.vue";
export default {
  name: "MappingSPU",
  components: {
    TableMonitorSPU,
    UploadSPU,
    UploadSPU_Error,
  },
  data() {
    return {
      tabIndex: 0,
      errorCount: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch("SPUProductActions/getDataSPU");
    this.errorCount = this.$store.getters[
      "SPUProductActions/GET_Data_Error"
    ].length;
  },
  computed: {
    GET_All: function () {
      return this.$store.getters["SPUProductActions/GET_All"];
    },
  },
  watch: {
    GET_All: function (v) {
      this.getDataSPU();
    },
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
    async getDataSPU() {
      try {
        await this.$store.dispatch["SPUProductActions/getDataSPU"];
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped></style>
