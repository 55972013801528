<template>
  <div class="col-12">
    <b-modal size="xl" id="ModalManageSPU" hide-footer title="Edit Product SPU">
      <b-table
        :empty-text="'ไม่พบข้อมูล / No Data'"
        :items="datainput.data"
        :fields="fields"
        :current-page="page_num"
        :per-page="page_size"
        hover
        outlined
        show-empty
        responsive
        :busy="loading"
        class="font-0-8s"
      >
         <template #cell(Error)="row">
          <div >
            <strong class="text-center text-twd my-2" v-if="row.item.Error=='1'">ไม่พบข้อมูลสินค้า</strong>
            <strong v-else>-</strong>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-main my-2">
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(action)="row">
          <span
            v-html="$svg_icon.remove"
            type="button"
            class="mr-1 text-twd"
            @click="OnClickRemove(row.item)"
          ></span>
        </template>
      </b-table>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ModalManageSPU",
  props: ["datainput"],
  data() {
    return {
      page_size: 10,
      loading: false,
      totalRows: 0,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "SPUCODE", label: "SPU", class: "text-left" },
        { key: "CLASS", label: "Class", class: "text-left" },
        { key: "SKUCODE", label: "Skucode", class: "text-left" },
        { key: "prNameTH", label: "Product Name", class: "text-left" },
        { key: "Error", label: "Error", class: "text-center" },
        { key: "Last_update", label: "Last_update", class: "text-left" },
        { key: "UpdateBy", label: "UpdateBy", class: "text-left" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
      items: null,
    };
  },
  async created() {
    await this.getdataSpuList();
  },
  methods: {
    async getdataSpuList() {
      this.items = this.datainput.data;
    },
    async OnClickRemove(item) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบข้อมูลหรือไม่? / Do you want to delete data?`
        );
        if (confirm) {
          let data = { Mode: "SKU", data: item };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/updatepimspu`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$emit("CallModalManageSPU", item.SPUCODE);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
